import React from "react"
import get from 'lodash/get'
import parse from 'html-react-parser'
import { graphql } from "gatsby"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from '../styles/Section'
import HeaderTitle from '../components/General/HeaderTitle/HeaderTitle'
import GlobalTextStyles from '../components/General/Text/GlobalTextStyles'
import RevealOpacity from '../components/Animation/RevealOpacity'

import { media } from '../lib/style-utils'

const SectionExtended = styled(Section)`
    position: relative;
    padding: 3rem 0;
    padding-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 2rem;
        padding-bottom: 2rem;
    `}
    ${media.desktop`
        padding: 6rem 0;
        width: 88%;
        max-width: 1200px;
        margin: 0 auto;
    `}
`

const Disclaimer = props => {
  const DataText = get(props, 'data.pageData.nodes[0].policytext', [])
  const seoData = get(props, 'data.pageData.nodes[0].seo', [])
  
  let data = {
    title: seoData.title,
    description: seoData.description,
    lang: 'en',
    image: seoData.social.facebook.image && seoData.social.facebook.image.url,
    imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
    robots: seoData.advanced.robots && seoData.advanced.robots
  }

  // slugs for translation
  const slugs = {
    slugNl: props.data.translatedUrl.nodes[0].slug,
    slugEn: props.data.pageData.nodes[0].slug,
  }

  return(
    <Layout form={true} data={props.data.site} location={props.location} slugs={slugs}>
      <SEO {...data}/>
      <HeaderTitle title="Disclaimer"/>
      <SectionExtended>
        <GlobalTextStyles>
          <RevealOpacity>{parse(DataText)}</RevealOpacity>
        </GlobalTextStyles>
      </SectionExtended>
    </Layout>
  )
}

export default Disclaimer

export const query = graphql`
{
  site{
    siteMetadata{
      languages {
        defaultLangKey
        langs
      }
    }
  }
  translatedUrl: allCraftDisclaimerDisclaimerEntry(filter: {siteId: {eq: 2}}) {
    nodes{
      slug
    }
  }
  pageData: allCraftDisclaimerDisclaimerEntry(filter: {siteId: {eq: 1}}) {
    nodes{
      title
      policytext
      seo {
        title
        description
        social {
          facebook {
            image {
              url
            }
          }
          twitter {
            image {
              url
            }
          }
        }
        advanced {
          robots
        }
      }
    }
  }
}
`