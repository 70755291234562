import React from 'react'
import styled from 'styled-components'
import { media,colors,fonts } from '../../../lib/style-utils'

import Section from '../../../styles/Section'
import SocialIcons from '../Social/Social'

import RevealOpacity from '../../Animation/RevealOpacity'
import { FormattedMessage } from 'react-intl';

/* SectionExtended */
const SectionExtended = styled(Section)`
    padding-top: 0; 
    padding-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
    `}
    ${media.desktop`
        width: 88%;
        max-width: 88%;
    `}
`

const ContactContentWrap = styled.div`
    width: 100%;
    background: ${colors.gradient};
    padding: 3rem 0 1.5rem 0;
    ${media.tablet`
        padding: 6rem 0;
    `}
`

const SectiontextWrapper = styled.div`
    position: relative;
`

const SectionText = styled.div`
    font-family: ${fonts.Montserrat};
    width: 100%;
`

const TextMain = styled.h1`
    display: inline-block;
    font-weight:800;
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-top: 1rem;
    color: orange !important;
    position: relative;
    padding: 1.5rem 1rem;
    z-index: 999;
    :before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${colors.white};
        z-index: -1;
    }
    ${media.tablet`
        font-size: 4rem;
        line-height: 3rem;
    `};
    ${media.desktop`
        font-size: 5rem;
        line-height: 4rem;
    `};
    ${media.midDesktop`
        font-size: 5rem;
        line-height: 4rem;
    `};
`

const SocialIconsWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;;
    width: auto;
    padding-top: 1rem;
    display: none;
    ${media.midDesktop`
        display: block;
    `};
    svg, svg g{
        fill: ${colors.white};
    }
`

const HeaderTitle = (props) => {
        return (
            <ContactContentWrap>                    
                <SectionExtended>
                    <SectiontextWrapper>
                        <RevealOpacity>
                            <SectionText>
                                <TextMain>
                                    <FormattedMessage id="navPrivacy"/>
                                </TextMain>
                            </SectionText>
                            <SocialIconsWrapper>
                                <SocialIcons />
                            </SocialIconsWrapper>
                        </RevealOpacity>
                    </SectiontextWrapper>
                </SectionExtended>
            </ContactContentWrap>
        )
}

export default HeaderTitle